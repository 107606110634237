export const trackpiece_per100m = 2;
export const sx = 2;
export const metrics = {
  bike: {
    scale: 1.5,
    between: 0.2,
    width: 0.8,
  },
  text_emissive_intensity: 0.5,
  sx,
};
